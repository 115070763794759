import React, { Component, Fragment } from 'react';
import MainLayout from '../layouts/mainLayout'
import MainPage from './getQoutes/mainPage'
import CatMainPage from './getQoutes/catMainPage'
import DogMainPage from './getQoutes/dogMainPage'
import Index from './getQoutes/index';
import { getPetBreeds, getPlans, getPaymentStatus, createQoute, createWithoutPayment, updateLead, getLead, getLeadById, getTamaraPaymentStatus } from '../api/pets-api';
import { notifications, upperCase, applyDiscountVat, convertAED } from '../helpers/utilities';
import { ALLOWED_EXTENSION, Reimburse, Deduction, Countries, CURRENCY, BenifitsMap } from '../constants';
import { Spin } from 'antd';

class GetQouteFlow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            qouteObj: {},
            finalQoute: {},
            showFlag: 'main',
            petTypeBreeds: [],
            plans: [],
            paymentSuccess: false,
            paymentFailed: false,
            policyNumber: '',
            spinning: false,
            tip: '',
            gotToIndex: 0,
            savedQuote: false,
            currentLeadId:'',
            createdPolicies:[]
        };
        this.populateQoute = this.populateQoute.bind(this);
        this.changeFlag = this.changeFlag.bind(this);
    }
    populateQoute(obj){
        this.setState({qouteObj:{...this.state.qouteObj, ...obj}})
        console.log('quoteObj', this.state.qouteObj);
        localStorage.setItem('quoteObj', JSON.stringify(this.state.qouteObj));
        
    }
    changeFlag(val){
        this.setState({showFlag:val})
        console.log(this.state.qouteObj);        
    }
    setFinalQoute = (qoute) => {
        this.setState({finalQoute:qoute});      
    }
    getFinalQoute = () => {
        return this.state.finalQoute;      
    }
    setCurrentLeadId = (leadId) => {
        this.setState({currentLeadId:leadId});   
    }
    setSpinnerAndTip = (spinner, tip) => {
        this.setState({spinning:spinner, tip:tip});  
    }

    async componentWillMount(){
        this.setState({ spinning: true, tip: "Loading..." });
        let resp = await getPetBreeds();
        let petTypeBreeds = resp.data.result;

        console.log('petTypeBreeds', petTypeBreeds);

        this.setState({petTypeBreeds})
        let plans = await getPlans();
        this.setState({plans: plans.data.result});
        this.setState({ spinning: false, tip: "" });

        if(this.props.location.pathname === '/cat') {
            this.changeFlag('cat');
        }else if(this.props.location.pathname === '/dog') {
            this.changeFlag('dog');
        }else if(this.props.location.pathname === '/payement_verification') {
            var retrievedObject = localStorage.getItem('quoteObj');
            if(retrievedObject){
                this.setState({qouteObj:JSON.parse(retrievedObject)});
                this.setState({ spinning: true, tip: "Processing Quote for You..." });
                const queryParams = new URLSearchParams(this.props.location.search);
                const ref = queryParams.get("ref")
                let res = await getPaymentStatus(ref);
                let paymentStatus = res.data.result.state;
                
                let quote = JSON.parse(localStorage.getItem('userData'))
                var pet = this.state.qouteObj.petsWithPlans[0];

                let seconPet = localStorage.getItem('addNewPet') == 'true' ? true : false;
                let renewPolicy = localStorage.getItem('renewPolicy') == 'true' ? true : false;

                let policyCost = 50;

                if(renewPolicy && quote.isContinues){
                    policyCost = 0;
                }

                var policyObj = {
                    planId: plans.data.result[pet.planId].id,
                    policyCurrency: plans.data.result[pet.planId].currency,
                    policyType: "H01",
                    deductableValue: Deduction[pet.deductionId],
                    copaymentValue: Reimburse[pet.reimburseId].reimbursment,
                    policyValue: Math.ceil((pet.plans[pet.planId]) * 3.67),
                    totalPaid: (convertAED(pet.plans[pet.planId])+ policyCost + applyDiscountVat((convertAED(pet.plans[pet.planId])+policyCost), 5, 'discount')),
                    paymentMethod: 'Full Payment'
                }
                console.log("policyObjBeforeSending", policyObj);


                let sum = 0;
                var petPolicies = this.state.qouteObj.petsWithPlans.map((obj, index) => {
                    let isDiscountApplied = index || seconPet || (renewPolicy && !obj.isPrimaryPet)

                    let planVal = Number(parseFloat(obj.plans[obj.planId] * 3.67).toFixed(2));
                    let discount = Number(parseFloat(isDiscountApplied ? applyDiscountVat(planVal, 5, 'discount') : 0).toFixed(2))
                    let vat = Number(parseFloat(isDiscountApplied ? (applyDiscountVat(planVal, 5) + policyCost) * 0.05 : (planVal + policyCost) * 0.05).toFixed(2));
                    let disValue = Number(parseFloat(isDiscountApplied ? ((applyDiscountVat(planVal, 5) + policyCost) + vat) : (planVal + policyCost + vat)).toFixed(2))
                    sum = sum + disValue;
                    let policyActualBenefits = plans.data.result[obj.planId].annualy == 5000 ? 20000 : plans.data.result[obj.planId].annualy == 10000 ? 35000 : 70000;
                    return {
                        planId: plans.data.result[obj.planId].id,
                        policyCurrency: plans.data.result[obj.planId].currency,
                        policyType: "H01",
                        deductableValue: Deduction[obj.deductionId],
                        copaymentValue: Reimburse[obj.reimburseId].reimbursment,
                        policyValue: planVal,
                        discount: parseFloat(discount).toFixed(2),
                        policyCost: parseFloat(policyCost).toFixed(2),
                        vat: parseFloat(vat).toFixed(2),
                        totalPaid: disValue,
                        ActualValueOfPolicy: policyActualBenefits,
                        paymentMethod: 'Full Payment'
                    }
                })

                if(seconPet){
                    localStorage.setItem('addNewPet', false);
                }
                if(renewPolicy){
                    localStorage.setItem('renewPolicy', false);
                }

                if(quote){
                    this.setFinalQoute(quote);
                    if(paymentStatus === 'PURCHASED' || paymentStatus === 'CAPTURED'){
                        this.setState({ spinning: true, tip: "Saving Quote for You..." });
                        quote.policy = policyObj;
                        quote.policies = petPolicies;
                        quote.paymentRef = ref;
                        quote.total = sum;
                        this.setState({paymentSuccess:true});
                        let leadId = localStorage.getItem('leadId');
                        if(leadId) {
                            quote.leadId = leadId;
                        }
                        let res = await createQoute(quote);
                        console.log('res', res);
                        if (res.data.errors) {
                            res.data.errors.map(error => {
                                notifications('Error', error.msg, 'error');
                            })
                            
                        } else {
                            this.setState({policyNumber:res.data.result.policyCode});
                            this.setState({createdPolicies:res.data.result.createdPolicies});
                            this.changeFlag('qoute');
                        }
                        this.setState({ spinning: false, tip: "" });
                    }
                    else{
                        notifications('Error', 'Payement Failed. Please try again.', 'error');
                        let obj = {
                            email:JSON.parse(retrievedObject).petsWithPlans[0].email,
                            planObj:retrievedObject,
                            userObj:quote
                        }
                        let rest = await updateLead(obj);
                        let res = await createWithoutPayment(quote);
                        console.log('res', res);
                        if (res.data.errors) {
                            res.data.errors.map(error => {
                                notifications('Error', error.msg, 'error');
                            })
                        } else {
                            notifications('Success', 'Your information saved without activation. Please proceed with the payement and activate your account', 'success');
                            this.setState({paymentFailed:true});
                            this.changeFlag('qoute');
                        }
                        this.setState({ spinning: false, tip: "" });
                    }
                }
                this.setState({ spinning: false });
            }
        } else if (this.props.location.pathname === '/email_link') {
            debugger;
            // console.log('email submitted', this.props.location.query.email);
            const queryParams = new URLSearchParams(this.props.location.search);
            let email = queryParams.get("email");
            if(email){
                email = email.replace(' ','+');
                this.setState({ spinning: true, tip: "Processing Quote for You..." });
                let userDetails = await getLead(email);
                if (userDetails.data.errors) {
                    userDetails.data.errors.map(error => {
                        notifications('Error', error.msg, 'error');
                    })
                } else {
                    if(userDetails.data.status == 'ok'){
                        debugger;
                        let planObj = userDetails.data.result.planObj;
                        this.setState({qouteObj:planObj});
                        
                        this.setState({savedQuote:true});
                        if(userDetails.data.result.userObj){
                            let userObj = userDetails.data.result.userObj;
                            localStorage.setItem('userData', JSON.stringify(userObj));
                            localStorage.setItem('quoteObj', JSON.stringify(planObj));
                            this.setState({gotToIndex:1});
                            this.changeFlag('qoute');
                        }else{
                            localStorage.setItem('quoteObj', planObj);
                            this.setState({gotToIndex:0});
                            this.changeFlag('qoute');
                        }
                        
                        // localStorage.setItem('quoteObj', planObj);
                        // this.setState({paymentFailed:true});
                        // this.changeFlag('qoute');
                    }
                }
                this.setState({ spinning: false, tip: "" });
            } else {
                let leadId = queryParams.get("id");
                leadId = leadId.replace(' ','');
                this.setState({ spinning: true, tip: "Processing Quote for You..." });
                this.setCurrentLeadId(leadId);
                let userDetails = await getLeadById(leadId);

                if (userDetails.data.errors) {
                    userDetails.data.errors.map(error => {
                        notifications('Error', error.msg, 'error');
                    })
                } else {
                    if(userDetails.data.status == 'ok'){
                        debugger;
                        let planObj = userDetails.data.result.planObj;
                        this.setState({qouteObj:planObj});
                        
                        this.setState({savedQuote:true});
                        if(userDetails.data.result.userObj){
                            let userObj = userDetails.data.result.userObj;
                            localStorage.setItem('userData', JSON.stringify(userObj));
                            localStorage.setItem('quoteObj', JSON.stringify(planObj));
                            localStorage.setItem('leadId', leadId);
                            if(userObj.user.firstName){
                                this.setState({gotToIndex:1});
                            }
                            else{
                                this.setState({gotToIndex:0});
                            }
                            this.changeFlag('qoute');
                        }else{
                            localStorage.setItem('quoteObj', JSON.stringify(planObj));
                            this.setState({gotToIndex:0});
                            this.changeFlag('qoute');
                        }
                        
                        // localStorage.setItem('quoteObj', planObj);
                        // this.setState({paymentFailed:true});
                        // this.changeFlag('qoute');
                    }
                }
                this.setState({ spinning: false, tip: "" });
            }
        } else if (this.props.location.pathname === '/tmrsc') {
            var retrievedObject = localStorage.getItem('quoteObj');
            if(retrievedObject){
                this.setState({qouteObj:JSON.parse(retrievedObject)});
                this.setState({ spinning: true, tip: "Processing Quote for You..." });
                const queryParams = new URLSearchParams(this.props.location.search);
                const ref = queryParams.get("orderId")
                const tamarapaymentStatus = queryParams.get("paymentStatus")
                
                let quote = JSON.parse(localStorage.getItem('userData'))
                var pet = this.state.qouteObj.petsWithPlans[0];
                let seconPet = localStorage.getItem('addNewPet') == 'true' ? true : false;
                let renewPolicy = localStorage.getItem('renewPolicy') == 'true' ? true : false;

                
                let policyCost = 51.50; // 50 AED + 1.50 AED for tamara payment gateway

                if(renewPolicy && quote.isContinues){
                    policyCost = 0;
                }

                let tamaraPolicyValue = convertAED(pet.plans[pet.planId] * 1.0499);

                var policyObj = {
                    planId: plans.data.result[pet.planId].id,
                    policyCurrency: plans.data.result[pet.planId].currency,
                    policyType: "H01",
                    deductableValue: Deduction[pet.deductionId],
                    copaymentValue: Reimburse[pet.reimburseId].reimbursment,
                    policyValue: tamaraPolicyValue,
                    totalPaid: (tamaraPolicyValue + policyCost + applyDiscountVat((tamaraPolicyValue + policyCost), 5, 'discount')),
                    paymentMethod: 'Tamara'
                }
                console.log("policyObjBeforeSending", policyObj);

                let sum = 0;
                var petPolicies = this.state.qouteObj.petsWithPlans.map((obj, index) => {

                    let isDiscountApplied = index || seconPet || (renewPolicy && !obj.isPrimaryPet)

                    tamaraPolicyValue = convertAED(obj.plans[obj.planId] * 1.0499);

                    let planVal = tamaraPolicyValue;
                    let discount = Number(parseFloat(isDiscountApplied ? applyDiscountVat(planVal, 5, 'discount') : 0).toFixed(2))
                    let vat = Number(parseFloat(isDiscountApplied ? (applyDiscountVat(planVal, 5) + policyCost) * 0.05 : (planVal + policyCost) * 0.05).toFixed(2));
                    let disValue = Number(parseFloat(isDiscountApplied ? ((applyDiscountVat(planVal, 5) + policyCost) + vat) : (planVal + policyCost + vat)).toFixed(2))
                    sum = sum + disValue;
                    let policyActualBenefits = plans.data.result[obj.planId].annualy == 5000 ? 20000 : plans.data.result[obj.planId].annualy == 10000 ? 35000 : 70000;
                    return {
                        planId: plans.data.result[obj.planId].id,
                        policyCurrency: plans.data.result[obj.planId].currency,
                        policyType: "H01",
                        deductableValue: Deduction[obj.deductionId],
                        copaymentValue: Reimburse[obj.reimburseId].reimbursment,
                        policyValue: planVal,
                        discount: parseFloat(discount).toFixed(2),
                        policyCost: parseFloat(policyCost).toFixed(2),
                        vat: parseFloat(vat).toFixed(2),
                        totalPaid: disValue,
                        ActualValueOfPolicy: policyActualBenefits,
                        paymentMethod: 'Tamara'
                    }
                })

                if(seconPet){
                    localStorage.setItem('addNewPet', false);
                }
                if(renewPolicy){
                    localStorage.setItem('renewPolicy', false);
                }



                if(quote){

                    this.setFinalQoute(quote);

                    if(tamarapaymentStatus == 'canceled'){

                        notifications('Error', 'Payement Cancelled. Please try again.', 'error');
                        let obj = {
                            email:JSON.parse(retrievedObject).petsWithPlans[0].email,
                            planObj:retrievedObject,
                            userObj:quote
                        }
                        //let rest = await updateLead(obj);
                        let res = await createWithoutPayment(quote);
                        console.log('res', res);
                        if (res.data.errors) {
                            res.data.errors.map(error => {
                                notifications('Error', error.msg, 'error');
                            })
                        } else {
                            notifications('Success', 'Your information saved without activation. Please proceed with the payement and activate your account', 'success');
                            this.setState({paymentFailed:true});
                            this.changeFlag('qoute');
                        }
                        this.setState({ spinning: false, tip: "" });

                    } else if (tamarapaymentStatus == 'failed'){

                        notifications('Error', 'Payement Cancelled. Please try again.', 'error');
                        let obj = {
                            email:JSON.parse(retrievedObject).petsWithPlans[0].email,
                            planObj:retrievedObject,
                            userObj:quote
                        }
                        //let rest = await updateLead(obj);
                        let res = await createWithoutPayment(quote);
                        console.log('res', res);
                        if (res.data.errors) {
                            res.data.errors.map(error => {
                                notifications('Error', error.msg, 'error');
                            })
                        } else {
                            notifications('Success', 'Your information saved without activation. Please proceed with the payement and activate your account', 'success');
                            this.setState({paymentFailed:true});
                            this.changeFlag('qoute');
                        }
                        this.setState({ spinning: false, tip: "" });

                    } else {
                        

                        let res = await getTamaraPaymentStatus(ref);
                        let paymentStatus = res.data.result.status;

                        if(paymentStatus === 'authorised'){
                            this.setState({ spinning: true, tip: "Saving Quote for You..." });
                            quote.policy = policyObj;
                            quote.policies = petPolicies;
                            quote.paymentRef = ref;
                            quote.total = sum;
                            this.setState({paymentSuccess:true});
                            let leadId = localStorage.getItem('leadId');
                            if(leadId) {
                                quote.leadId = leadId;
                            }
                            let res = await createQoute(quote);
                            console.log('res', res);
                            if (res.data.errors) {
                                res.data.errors.map(error => {
                                    notifications('Error', error.msg, 'error');
                                })
                                
                            } else {
                                this.setState({policyNumber:res.data.result.policyCode});
                                this.setState({createdPolicies:res.data.result.createdPolicies});
                                this.changeFlag('qoute');
                            }
                            this.setState({ spinning: false, tip: "" });
                        }
                        else{
                            notifications('Error', 'Payement Failed. Please try again.', 'error');
                            let obj = {
                                email:JSON.parse(retrievedObject).petsWithPlans[0].email,
                                planObj:retrievedObject,
                                userObj:quote
                            }
                            //let rest = await updateLead(obj);
                            let res = await createWithoutPayment(quote);
                            console.log('res', res);
                            if (res.data.errors) {
                                res.data.errors.map(error => {
                                    notifications('Error', error.msg, 'error');
                                })
                            } else {
                                notifications('Success', 'Your information saved without activation. Please proceed with the payement and activate your account', 'success');
                                this.setState({paymentFailed:true});
                                this.changeFlag('qoute');
                            }
                            this.setState({ spinning: false, tip: "" });
                        }
                    }
                }
                this.setState({ spinning: false });

            }
        }
        
    }

    render() {
        return (
            <Fragment>
                
                <MainLayout>
                
                    { this.state.showFlag === 'main' && this.state.petTypeBreeds && <MainPage plans={this.state.plans} petTypeBreeds={this.state.petTypeBreeds} changeFlag={this.changeFlag} populateQoute={this.populateQoute} preQoute={this.state.qouteObj} spinning={this.state.spinning} tip={this.state.tip} currentLeadId={this.state.currentLeadId} setLeadId={this.setCurrentLeadId} setSpinnerAndTip={this.setSpinnerAndTip} {...this.props}/>}
                    { this.state.showFlag === 'cat' && this.state.petTypeBreeds && <CatMainPage plans={this.state.plans} petTypeBreeds={this.state.petTypeBreeds} changeFlag={this.changeFlag} populateQoute={this.populateQoute} preQoute={this.state.qouteObj} spinning={this.state.spinning} tip={this.state.tip} currentLeadId={this.state.currentLeadId} setLeadId={this.setCurrentLeadId} setSpinnerAndTip={this.setSpinnerAndTip} {...this.props}/>}
                    { this.state.showFlag === 'dog' && this.state.petTypeBreeds && <DogMainPage plans={this.state.plans} petTypeBreeds={this.state.petTypeBreeds} changeFlag={this.changeFlag} populateQoute={this.populateQoute} preQoute={this.state.qouteObj} spinning={this.state.spinning} tip={this.state.tip} currentLeadId={this.state.currentLeadId} setLeadId={this.setCurrentLeadId} setSpinnerAndTip={this.setSpinnerAndTip} {...this.props}/>}
                    { this.state.showFlag === 'qoute' && <Index plans={this.state.plans} gotToIndex={this.state.gotToIndex} setFinalQoute={this.setFinalQoute} getFinalQoute={this.getFinalQoute} petTypeBreeds={this.state.petTypeBreeds} changeFlag={this.changeFlag} populateQoute={this.populateQoute} preQoute={this.state.qouteObj} payementSuccess={this.state.paymentSuccess} paymentFailed={this.state.paymentFailed} savedQuote={this.state.savedQuote} policyNumber={this.state.policyNumber} createdPolicies={this.state.createdPolicies} currentLeadId={this.state.currentLeadId} setLeadId={this.setCurrentLeadId}/>}
                
                </MainLayout>
                
            </Fragment>
        );
    }
}

export default GetQouteFlow;