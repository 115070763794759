import React, { Component, Fragment } from 'react';
import Geocode from "react-geocode";
import { Typography, Tooltip, Row, Col, Button, Image, Modal, Form, Input, Radio, Select, Card, Divider, Spin, Upload } from 'antd';
import MaskedInput from 'antd-mask-input'
import { ALLOWED_EXTENSION, Reimburse, Deduction, Countries, CURRENCY, BenifitsMap } from "../../constants";
import Map from './map';
import moment from 'moment';
import { createQoute, uploadIds, makePayment, validateQoute, updateLead, getLead, sendLeadEmail, makePaymentViaTamara } from '../../api/pets-api';
import { notifications, upperCase, applyDiscountVat, convertAED, getUser } from '../../helpers/utilities';
import imageCompression from 'browser-image-compression';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import ReactFlagsSelect from 'react-flags-select';
import { UploadOutlined } from '@ant-design/icons';
import { PasswordInput } from 'antd-password-input-strength';
import Sidebar from '../../components/common/sidebar';
import tamaralogo from '../../assets/images/tamara-en.png'

const { Paragraph, Title } = Typography;
const { Option } = Select;

Geocode.setApiKey("AIzaSyClUQeIMmxUke2X7jkwaBptg4k52-Ac0JE");
Geocode.enableDebug();

let date = new Date();
var dt = date.getDate();

var acc = new Date()
acc.setDate(date.getDate() + 6 )
var accMonth = acc.toLocaleString('en-us', { month: 'long' });

var ill = new Date()
ill.setDate(date.getDate() + 14 )
var illMonth = ill.toLocaleString('en-us', { month: 'long' });

var kne = new Date()
kne.setMonth(date.getMonth() + 6);
var kneMonth = kne.toLocaleString('en-us', { month: 'long' });

date.setDate(date.getDate() + 1);
// if (dt >= 15) {
//   date.setMonth(date.getMonth() + 1);
// }
let longMonth = date.toLocaleString('en-us', { month: 'long' });

var staticAddress = {latitude: 25.276987, longitude: 55.296249, addressesLine1: 'Dubai UAE', city: 'Dubai', country: 'UAE' }
class yourinfo extends Component {
    state = {
        value: 1,
        exp_month: "Dec (12)",
        exp_year: "Dec (12)",
        visible: false,
        spinning: false,
        tip: 'Uploading Emirates Id...',
        idUploadFlag: true,
        total: 0,
        address: [],
        lat: '',
        lng: '',
        nationality: '',
        appartment: '',
        street: '',
        city: '',
        visibleSP: false,
        petsWithPlans: [],
        frontUploaded: false,
        backUploaded:false,
        runningUserObject:{},
        pricingDetails:{},
        selectedPaymentMethod: 'ot',
        selectedInstallments: 4,
        tamaraTotal: 0,
        tamaraInstallmentValue: 0,
        policyFee:50,
        enableTamara:true,
        eidFrontFilename:'',
        eidBackFilename:'',
        isPolicyExpired:false
    };
    
    formRef = React.createRef();
    addressFormRef = React.createRef();
    secPetParentFormRef = React.createRef();
    onChange = e => {
        console.log('radio checked', e.target.value);
        this.setState({
            value: e.target.value,
        });
    };

    showModal = () => {
        debugger;
        if(!this.props.paymentFailed){
            this.setState({
                visible: true,
            });
            if(this.props.savedQuote){
                let quote = JSON.parse(localStorage.getItem('userData'))
                if(quote){
                    console.log(this.addressFormRef.current)
                    // this.addressFormRef.current.setFieldsValue({
                    //     appartment:quote.user.addresses[0].apartmentNumber,
                    //     street:quote.user.addresses[0].city,
                    //     city:quote.user.addresses[0].street
                    // })
                }
            }
        }
    };

    showModalSP = () => {
        if(!this.props.paymentFailed){
            this.setState({
                visibleSP: true,
            });
        }
    };

    handleCancel = e => {
        this.setState({
            visible: false,
        });
    };

    handleCancelSP = e => {
        this.setState({
            visibleSP: false,
        });
    };

    formSetLocation = (values) => {
        this.formRef.current.setFieldsValue({
            add_address: values.city,
        });
        // this.setState({ address: [address] },()=>{
        //     notifications('Location Added Successfully','Location','success',2)
        // });
        this.setState({ visible: false, appartment: values.appartment, street: values.street, city: values.city, address: [staticAddress] },()=>{
            notifications('Address Added Successfully','Location','success',2)
        })
        // this.formRef.current.setFieldsValue({
        //     add_address: values.set_location,
        //   });

        console.log('Success:', values);
    };

    formSetSecPetParent = (values) => {
        // this.formRef.current.setFieldsValue({
        //     add_address: values.city,
        // });
        // // this.setState({ address: [address] },()=>{
        // //     notifications('Location Added Successfully','Location','success',2)
        // // });
        this.setState({ visibleSP: false },()=>{
            notifications('Secondary Pet Parent Added Successfully','Location','success',2)
        })
        // // this.formRef.current.setFieldsValue({
        // //     add_address: values.set_location,
        // //   });

        console.log('Success:', values);
    };

    formSetLocationFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    formSetSecPetParentFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    onChangeChecked(checked) {
        console.log(`switch to ${checked}`);
    }

    infoSubmit = async (values) => {

        debugger;

        if(!this.props.hideUserInfo && !this.props.renewPolicy && !this.props.paymentFailed && (!this.state.frontUploaded || !this.state.backUploaded)){
            notifications('Error', 'Please upload your Emirates ID images', 'error');
            return;
        }

        if(!this.props.paymentFailed){
            console.log(`switch to`, values, this.props.preQoute);
            let pets = [];
            if(this.props.renewPolicy){
                this.props.preQoute?.petsWithPlans?.map(pet => {
                    pets.push({
                        id: this.props.policy.id,
                        name: pet.name,
                        petTypeId: pet.type,
                        breedId: pet.breed,
                        dob: moment(pet.age).format('YYYY/MM/DD'),
                        gender: pet.gender,
                        isPrimaryPet: pet.isPrimaryPet ? true : false
                    })
                });
            }else{
                this.props.preQoute?.petsWithPlans?.map(pet => {
                    pets.push({
                        name: pet.name,
                        petTypeId: pet.type,
                        breedId: pet.breed,
                        dob: moment(pet.age).format('YYYY/MM/DD'),
                        gender: pet.gender,
                        isPrimaryPet: pet.isPrimaryPet ? true : false
                    })
                });
            }

            let existingUser = getUser();
            console.log('User Saved In Cookie', existingUser);

            var usrObj;

            if(existingUser){
                usrObj = {...existingUser};
            }else{
                if(this.props.renewPolicy){
                    usrObj = {
                        id: this.props.policy.userId,
                        firstName: this.props.policy.firstName,
                        lastName: this.props.policy.lastName,
                        email: this.props.policy.email,
                        nationality: this.props.policy.nationality,
                        emiratesId: this.props.policy.emiratesId,
                        phoneNo: this.props.policy.phoneNo,
                        addresses: [{ ...this.state.address[0], apartmentNumber: this.props.policy.addressApartment, city: this.props.policy.addressCity, street: this.props.policy.addressStreet, country: this.props.policy.addressCountry  }],
                        eidFront:this.props.policy.eidFront,
                        eidBack:this.props.policy.eidBack
                    }
                }else{
                    usrObj = {
                        title: values.title,
                        firstName: values.first_name,
                        lastName: values.last_name,
                        email: values.email_address,
                        password: values.password,
                        nationality: Countries[values.nationality],
                        emiratesId: values.emiratesId,
                        phoneNo: values.phone,
                        addresses: [{ ...this.state.address[0], apartmentNumber: this.state.appartment, city: this.state.city, street: this.state.street, }],
                        eidFront:this.state.eidFrontFilename,
                        eidBack:this.state.eidBackFilename
                    }
                }
            }

            let finalObj = {
                user: usrObj,
                pets: pets,
                total: this.state.total,
                effectiveDate: date
            } 

            if(this.props.renewPolicy){
                finalObj = {
                    ...finalObj,
                    renewedPolicyId: this.props.policy.policyId,
                    isRenewal: true,
                    isContinues: !this.state.isPolicyExpired
                }
            }


            //console.log(finalObj, 'finalObj');
            // try{
            this.setState({ spinning: true, tip: "Generating Quote for You. Please avoid refreshing or closing the browser." });
            localStorage.setItem('userData', JSON.stringify(finalObj));
            window.scrollTo(0,0);
            let res = await validateQoute(finalObj);
            console.log('res', res);

            if (res.data.errors) {
                res.data.errors.map(error => {
                    notifications('Error', error.msg, 'error');
                })
                this.setState({ spinning: false });
            } else {

                

                this.props.setFinalQoute(finalObj);
                //this.props.setFinalQoute({user:res.data.result.user, pets:res.data.result.pets});
                
                if(this.state.selectedPaymentMethod == 'ot')
                {
                    await this.processOneTimePayment(this.state.total, finalObj, this.props.preQoute?.petsWithPlans)
                }
                else 
                {
                    await this.processTamaraPayment(this.state.tamaraTotal, this.state.selectedInstallments, finalObj, this.props.preQoute?.petsWithPlans)
                }
                

                this.props.updateGTAG("add_payment_info");
                
                //this.props.switchStep();
            }
        }
        else{
            this.setState({ spinning: true, tip: "Generating Quote for You. Please avoid refreshing or closing the browser." });
            var finalObj = JSON.parse(localStorage.getItem('userData'));
            window.scrollTo(0,0);
            let res = await validateQoute(finalObj);
            console.log('res', res);

            if (res.data.errors) {
                res.data.errors.map(error => {
                    notifications('Error', error.msg, 'error');
                })
                this.setState({ spinning: false });
            } else {
                this.props.setFinalQoute(finalObj);
                //this.props.setFinalQoute({user:res.data.result.user, pets:res.data.result.pets});
                
                if(this.state.selectedPaymentMethod == 'ot')
                {
                    await this.processOneTimePayment(this.state.total, finalObj, this.props.preQoute?.petsWithPlans)
                }
                else 
                {
                    await this.processTamaraPayment(this.state.tamaraTotal, this.state.selectedInstallments , finalObj, this.props.preQoute?.petsWithPlans)
                }

                this.props.updateGTAG("add_payment_info");
                
                //this.props.switchStep();
            }
        }
        
    }

    async processOneTimePayment(total, savingPaymentInfo, petsWithPlans){
        let sum = total * 100;

        let payObj = {
            amount: sum,
            userAndPets:savingPaymentInfo,
            petsWithPlans: petsWithPlans
        }

        if(savingPaymentInfo)
        {
            payObj.userAndPets = savingPaymentInfo
        }

        let payRes = await makePayment(payObj);

        console.log(payRes);

        if(payRes.data.result){
            window.location.replace(payRes.data.result);
        } else {
            notifications('Some issue encountered while processing your request, Tamara is unable to process','Warning','error',2)
        }
    }

    async processTamaraPayment(total, installmentCount, savingPaymentInfo, petsWithPlans){
        let payObj = {
            amount: total,
            installmentCount: installmentCount,
            userAndPets:savingPaymentInfo,
            petsWithPlans: petsWithPlans
        }

        if(savingPaymentInfo)
        {
            payObj.userAndPets = savingPaymentInfo
        }

        let payRes = await makePaymentViaTamara(payObj);

        if(payRes.data.result.checkout_url){
            window.location.replace(payRes.data.result.checkout_url);
        } else {
            notifications('Some issue encountered while processing your request, Tamara is unable to process','Warning','error',2)
        }
    }

    onPaymentMethodChange(e){
        // let increasePresentage = 0;
        // if(e == 'mi'){
        //     increasePresentage = 4.99;

        // }
        // this.calculatePrices(increasePresentage);
        if(e == 'mi' && this.state.tamaraTotal > 20000){
            notifications('Total is more than AED 20000, Tamara is unable to process','Warning','error',2)
        }else{
            this.setState({ selectedPaymentMethod: e});
        }
    }

    async onInstallmentCountChange(e) {
        debugger;
        await this.setState({ selectedInstallments: e.target.value});
        this.calculateTamaraPrices(4.99);
    }

    async submitPayment(paymentType) {
        await this.setState({ selectedPaymentMethod: paymentType});
    }
    
    componentDidMount() {
        window.scrollTo(0,0);
        navigator.geolocation.getCurrentPosition((position) => {
            this.setState({ lat: position.coords.latitude, lng: position.coords.longitude });
        });

        let selectedPetAge = new Date();

        this.props.preQoute?.petsWithPlans?.map(pet => {
            //if(selectedPetAge > pet.age)
                selectedPetAge = new Date(pet.age)
        });

        let datesAdjested = false;

        let isPolicyExpired = true;

        if(this.props.renewPolicy){

            debugger;
            let effectiveDate = new Date(this.props.policy.effectiveDate);
            let expireDate = new Date(this.props.policy.effectiveDate);

            expireDate.setFullYear(expireDate.getFullYear() + 1);
            expireDate.setDate(expireDate.getDate() - 1);

            let currentDate = new Date();

            let newPolicyEffectiveDate = new Date();

            if(currentDate < expireDate){
                isPolicyExpired = false;
                newPolicyEffectiveDate = expireDate;
                newPolicyEffectiveDate.setDate(newPolicyEffectiveDate.getDate() + 1);

                date = newPolicyEffectiveDate;
                acc = newPolicyEffectiveDate
                accMonth = acc.toLocaleString('en-us', { month: 'long' });
                ill = newPolicyEffectiveDate
                illMonth = ill.toLocaleString('en-us', { month: 'long' });
                kne = newPolicyEffectiveDate
                kneMonth = kne.toLocaleString('en-us', { month: 'long' });
                longMonth = date.toLocaleString('en-us', { month: 'long' });

                datesAdjested = true;
            }
        }
        
        this.setState({isPolicyExpired : isPolicyExpired});

        if(!datesAdjested) {

            try{
                if(selectedPetAge){
                    selectedPetAge = selectedPetAge.setDate(14);
        
                    let ageMthDiff = Math.floor(moment().diff(selectedPetAge, 'months', true));
        
                    if (ageMthDiff < 2) {
                        let dateCopy = moment(selectedPetAge);
                        let nextDate = dateCopy.add(2, 'M');
                        console.log(ageMthDiff, nextDate);
        
                        date = nextDate.toDate();
        
                        acc = nextDate.toDate()
                        acc.setDate(date.getDate() + 6)
                        accMonth = acc.toLocaleString('en-us', { month: 'long' });
        
                        ill = nextDate.toDate()
                        ill.setDate(date.getDate() + 14)
                        illMonth = ill.toLocaleString('en-us', { month: 'long' });
        
                        kne = nextDate.toDate()
                        kne.setMonth(date.getMonth() + 6);
                        kneMonth = kne.toLocaleString('en-us', { month: 'long' });
        
                        date.setDate(date.getDate() + 1);
                        // if (dt >= 15) {
                        //   date.setMonth(date.getMonth() + 1);
                        // }
                        longMonth = date.toLocaleString('en-us', { month: 'long' });
        
                    }
        
                    console.log('selected pet age', selectedPetAge);
                }
            }catch(error){
                console.log(error);
            }

        }



        let seconPet = this.props.addNewPet;
        console.log("secont pet", seconPet);

        localStorage.setItem('addNewPet', seconPet);
        localStorage.setItem('renewPolicy', this.props.renewPolicy);
        let policyValue = 50;

        if(this.props.renewPolicy){
            policyValue = !isPolicyExpired ? 0 : policyValue;
            seconPet = false;
        }

        

        debugger;
        let sum = this.state.total
        var pets = this.props.preQoute?.petsWithPlans.map((plan, index) => {

            let isDiscountApplied = index || seconPet || (this.props.renewPolicy && !this.props.policy.isPrimaryPet)

            let planVal = Number(parseFloat(plan.plans[plan.planId] * 3.67).toFixed(2));
            let vat = Number(parseFloat(applyDiscountVat(isDiscountApplied ? (applyDiscountVat(planVal + policyValue, 5)) : (planVal+policyValue), 5, 'discount')).toFixed(2))
            let discount = Number(parseFloat(isDiscountApplied ? applyDiscountVat(planVal, 5, 'discount') : 0).toFixed(2))
            let vat2 = Number(parseFloat(isDiscountApplied ? (applyDiscountVat(planVal, 5) + policyValue) * 0.05 : (planVal + policyValue) * 0.05).toFixed(2));
            let disValue = Number(parseFloat(isDiscountApplied ? applyDiscountVat((planVal + vat + policyValue), 5) : (planVal + vat + policyValue)).toFixed(2));
            let disValue2 = Number(parseFloat(isDiscountApplied ? ((applyDiscountVat(planVal, 5) + policyValue) + vat2) : (planVal + policyValue + vat2)).toFixed(2))
            sum = (sum + disValue2)
            var pet = {
                planVal: parseFloat(planVal).toFixed(2),
                discount: parseFloat(discount).toFixed(2),
                vat: parseFloat(vat2).toFixed(2),
                total: parseFloat(disValue2).toFixed(2),
                policyValue: policyValue,
                ...plan
            }
            return pet;
        });
        console.log(sum,this.props.preQoute);
        this.setState({ total: sum, policyFee: policyValue });
        this.setState({petsWithPlans : pets});

        if(this.props.paymentFailed || this.props.savedQuote){
            debugger;
            let quote = JSON.parse(localStorage.getItem('userData'))
            if(quote && quote.user && quote.user.firstName){
                this.formRef.current.setFieldsValue({
                    title: quote.user.title,
                    first_name: quote.user.firstName,
                    last_name: quote.user.lastName,
                    email_address: quote.user.email,
                    emiratesId: quote.user.emiratesId,
                    phone: quote.user.phoneNo,
                    add_address: quote.user.addresses[0] ? quote.user.addresses[0].city : ''
                });
                this.setState({ nationality: this.findCountry(quote.user.nationality) });
                console.log('sample', this.state.nationality);
                console.log(this.formRef)
                if(quote.user.addresses[0])
                {
                    this.setState({ appartment: quote.user.addresses[0].appartment, street: quote.user.addresses[0].street, city: quote.user.addresses[0].city, address: [staticAddress] })
                }
                
                
            }
        }
        this.calculatePrices(0, isPolicyExpired)
    }

    calculatePrices(increasePresentage, isPolicyExpired){

        let valueToMultiply = 1;
        let policyValue = 50;
        if(increasePresentage){
            valueToMultiply = (increasePresentage / 100.0) + 1;
            policyValue = increasePresentage != 0 ? 51.50 : 50;
        }

        let seconPet = this.props.addNewPet;
        if(this.props.renewPolicy){
            policyValue = !isPolicyExpired ? 0 : policyValue;
            seconPet = false;
        }

        let sum = 0
        var pets = this.props.preQoute?.petsWithPlans.map((plan, index) => {

            let isDiscountApplied = index || seconPet || (this.props.renewPolicy && !this.props.policy.isPrimaryPet)

            let planVal = (Number(parseFloat(plan.plans[plan.planId] * 3.67).toFixed(2))) * valueToMultiply;
            let vat = Number(parseFloat(applyDiscountVat(isDiscountApplied ? (applyDiscountVat(planVal + policyValue, 5)) : (planVal+policyValue), 5, 'discount')).toFixed(2))
            let discount = Number(parseFloat(isDiscountApplied ? applyDiscountVat(planVal, 5, 'discount') : 0).toFixed(2))
            let vat2 = Number(parseFloat(isDiscountApplied ? (applyDiscountVat(planVal, 5) + policyValue) * 0.05 : (planVal + policyValue) * 0.05).toFixed(2));
            let disValue = Number(parseFloat(isDiscountApplied ? applyDiscountVat((planVal + vat + policyValue), 5) : (planVal + vat + policyValue)).toFixed(2));
            let disValue2 = Number(parseFloat(isDiscountApplied ? ((applyDiscountVat(planVal, 5) + policyValue) + vat2) : (planVal + policyValue + vat2)).toFixed(2))
            sum = (sum + disValue2)
            var pet = {
                planVal: parseFloat(planVal).toFixed(2),
                discount: parseFloat(discount).toFixed(2),
                vat: parseFloat(vat2).toFixed(2),
                total: parseFloat(disValue2).toFixed(2),
                policyValue: parseFloat(policyValue).toFixed(2),
                ...plan
            }
            return pet;
        });
        console.log(sum,this.props.preQoute);
        this.setState({ total: sum });
        this.setState({petsWithPlans : pets});

        this.calculateTamaraPrices(4.99, isPolicyExpired);

    }

    calculateTamaraPrices(increasePresentage, isPolicyExpired){

        let valueToMultiply = 1;
        let policyValue = 50;
        if(increasePresentage){
            valueToMultiply = (increasePresentage / 100.0) + 1;
            policyValue = increasePresentage != 0 ? 51.50 : 50;
        }

        let seconPet = this.props.addNewPet;
        if(this.props.renewPolicy){
            policyValue = !isPolicyExpired ? 0 : policyValue;
            seconPet = false;
        }

        let sum = 0
        var pets = this.props.preQoute?.petsWithPlans.map((plan, index) => {

            let isDiscountApplied = index || seconPet || (this.props.renewPolicy && !this.props.policy.isPrimaryPet)

            let planVal = (Number(parseFloat(plan.plans[plan.planId] * 3.67).toFixed(2))) * valueToMultiply;
            let vat = Number(parseFloat(applyDiscountVat(isDiscountApplied ? (applyDiscountVat(planVal + policyValue, 5)) : (planVal+policyValue), 5, 'discount')).toFixed(2))
            let discount = Number(parseFloat(isDiscountApplied ? applyDiscountVat(planVal, 5, 'discount') : 0).toFixed(2))
            let vat2 = Number(parseFloat(isDiscountApplied ? (applyDiscountVat(planVal, 5) + policyValue) * 0.05 : (planVal + policyValue) * 0.05).toFixed(2));
            let disValue = Number(parseFloat(isDiscountApplied ? applyDiscountVat((planVal + vat + policyValue), 5) : (planVal + vat + policyValue)).toFixed(2));
            let disValue2 = Number(parseFloat(isDiscountApplied ? ((applyDiscountVat(planVal, 5) + policyValue) + vat2) : (planVal + policyValue + vat2)).toFixed(2))
            sum = (sum + disValue2)
            var pet = {
                planVal: parseFloat(planVal).toFixed(2),
                discount: parseFloat(discount).toFixed(2),
                vat: parseFloat(vat2).toFixed(2),
                total: parseFloat(disValue2).toFixed(2),
                policyValue: parseFloat(policyValue).toFixed(2),
                ...plan
            }
            return pet;
        });

        debugger;

        let installmentValue = (Number(parseFloat(sum / Number(this.state.selectedInstallments)).toFixed(2)));
        this.setState({ tamaraTotal: sum, tamaraInstallmentValue: installmentValue });
    }

    findCountry(value){
        for(var key in Countries){
            if(Countries[key] == value) return key;
        }
        return false;
    }

    checkType = (petType) => {
        let img = ''
        this.props.petTypeBreeds.map(type => {
            if (type.id == petType) {
                if (type.name == 'Dog') img = "/images/pet-paws.png"
                else img = "/images/pet-paws.png"
            }
        })
        return img;
    }

    setAddress = (address) => {
        console.log(address);
        this.formRef.current.setFieldsValue({
            add_address: address.addressesLine1,
        });
        this.setState({ address: [address] },()=>{
            notifications('Location Added Successfully','Location','success',2)
        });
    }

    setNatinality = (code) => {
        this.setState({ nationality: code });
        this.formRef.current.setFieldsValue({ nationality: code });
    }

    uploadId = async (options, side) => {
        let { onSuccess, onError, file, onProgress } = options;
    
        try {
            // Image compression options
            const compressionOptions = {
                maxSizeMB: 1.5,
                useWebWorker: true,
            };
    
            // Compress the file
            const compressedFile = await imageCompression(file, compressionOptions);
    
            // Extract the file extension
            let ext = compressedFile.name.split('.').pop();
            // Generate the new file name based on emiratesId and side
            let name = `${this.formRef.current.getFieldValue('emiratesId')}-${side}.${ext}`;
            let newFile = new File([compressedFile], name);
    
            debugger;

            // Create FormData object
            const fmData = new FormData();
            const config = {
                headers: { "content-type": "multipart/form-data" },
            };
    
            // Append file and side to FormData
            fmData.append("image", newFile);
            fmData.append("side", side);
    
            // Set loading state
            this.setState({ spinning: true });
    
            // Make the API call to upload the file
            const res = await uploadIds(fmData, config);
    
            // Reset loading state
            this.setState({ spinning: false });
    
            if (res.data.status === "ok") {
                onSuccess("Ok");
                notifications(res.data.message, 'Uploads', 'success', 3);
    
                // Update the state based on the side
                if (side === 'front') {
                    this.setState({ frontUploaded: true, eidFrontFilename: res.data.data.fileUrl });
                } else {
                    this.setState({ backUploaded: true, eidBackFilename: res.data.data.fileUrl });
                }
            } else {
                onError("Error");
                notifications(res.data.message, 'Uploads', 'error', 3);
    
                // Update the state based on the side
                if (side === 'front') {
                    this.setState({ frontUploaded: false });
                } else {
                    this.setState({ backUploaded: false });
                }
            }
        } catch (err) {
            console.error("Error: ", err);
    
            onError({ err });
            this.setState({ spinning: false });
            notifications('Please check the selected file. Only images are accepted. [jpg, jpeg, png]. Feel free to contact us via support@osloinsurance.pet if you are facing any challenges', 'Uploads', 'error', 3);
    
            // Update the state based on the side
            if (side === 'front') {
                this.setState({ frontUploaded: false });
            } else {
                this.setState({ backUploaded: false });
            }
        }
    };

    idFrontChange = (file) => {

    }

    idBackChange = (file) => {

    }

    idChange = (e) => {
        // console.log(e.target.value.replace(/[^0-9]/g,"").length);
        let idCount = e.target.value.replace(/[^0-9]/g, "").length;
        idCount === 15 ? this.setState({ idUploadFlag: false }) : this.setState({ idUploadFlag: true })

    }

    idUploadClick = (e) => {
        console.log(this.state.idUploadFlag);

        if (this.state.idUploadFlag)
            notifications('Provide Emirates ID first', 'Id Required', 'error');
    }

    infoValueChanged = (changedValues, allValues) => {
        // console.log("ChangedValues", changedValues);
        console.log("AllValues", allValues);
        let usrObj = {
            title: allValues.title,
            firstName: allValues.first_name,
            lastName: allValues.last_name,
            email: allValues.email_address,
            nationality: allValues.nationality ? Countries[allValues.nationality] : '',
            emiratesId: allValues.emiratesId,
            phoneNo: allValues.phone,
            addresses: [{ ...this.state.address[0], appartment: this.state.appartment, city: this.state.city, street: this.state.street, }]
        }
        console.log('Constructed usrObj', usrObj);
        this.setState({ runningUserObject: usrObj })
    }
    
    saveLeadInformation = async () => {

        let pets = [];
            this.props.preQoute?.petsWithPlans?.map(pet => {
                pets.push({
                    name: pet.name,
                    petTypeId: pet.type,
                    breedId: pet.breed,
                    dob: moment(pet.age).format('YYYY/MM/DD'),
                    gender: pet.gender
                })
            });



        let finalObj = {
            user: this.state.runningUserObject,
            pets: pets,
            total: this.state.total
        } 

        let obj = {
            id:this.props.currentLeadId,
            email:this.props.preQoute.petsWithPlans[0].email,
            planObj:this.props.preQoute,
            userObj:finalObj
        }
        let res = await updateLead(obj);
        if (res.data.errors) {
            res.data.errors.map(error => {
                notifications('Error', error.msg, 'error');
            })
        } else {
            let usrObj = {
                user:{
                    email:obj.email,
                    leadId:res.data.result.id
                }
            }
    
            let resEmail = await sendLeadEmail(usrObj);
            if (resEmail.data.errors) {
                resEmail.data.errors.map(error => {
                    notifications('Error', error.msg, 'error');
                })
            } else {
                notifications('Success', 'Please check your inbox for email link for saved quotation.', 'success');
            }
        }
    }

    render() {
        return (
            <Fragment>
                <Row gutter={15}>
                    <Col xl={16} lg={16} md={24} sm={24} xs={24}>
                        <Spin spinning={this.state.spinning} tip={this.state.tip} size="large">
                            <Form
                                name="basic"
                                size={"large"}
                                className="customise-form"
                                onFinish={this.infoSubmit}
                                onFinishFailed={this.infoFailed}
                                onValuesChange={this.infoValueChanged}
                                ref={this.formRef}
                            >
                                {
                                    (!this.props.hideUserInfo && !this.props.renewPolicy) &&
                                
                                    <div>
                                        <div className="section-gray">
                                        <div className="section-heading">Your info</div>
                                    </div>
                                    <div className="section-gray">
                                        <Row gutter={15}>
                                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                <Title className="text-light-green" level={2}>Primary pet parent</Title>
                                            </Col>
                                            {/* <Col xs={24} sm={24} md={3} lg={3} xl={3}>
                                                <Form.Item
                                                    name="title"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input title!',
                                                        },
                                                    ]}
                                                >
                                                    <Select placeholder="Title*">
                                                        <Option key="Mr" value="Mr">Mr.</Option>
                                                        <Option key="Mrs" value="Mrs">Mrs.</Option>
                                                        <Option key="Miss" value="Miss">Miss</Option>
                                                    </Select>
                                                </Form.Item>
                                            </Col> */}
                                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                <Form.Item
                                                    name="first_name"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input your first name!',
                                                        },
                                                    ]}
                                                >
                                                    <Input placeholder={"First Name *"} />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                <Form.Item
                                                    name="last_name"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input your last name!',
                                                        },
                                                    ]}
                                                >
                                                    <Input placeholder={"Last Name *"} />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                <Form.Item
                                                    name="add_address"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input your address!',
                                                        },
                                                    ]}
                                                >
                                                    <Input readOnly={true} placeholder={"Add Address"} onClick={this.showModal} prefix={<i className="fa fa-plus-circle text-light-green"></i>} />
                                                </Form.Item>
                                                <Modal
                                                    className="find-location-modal"
                                                    title={<div className="text-dark-green font-600">Set your Location</div>}
                                                    visible={this.state.visible}
                                                    footer={false}
                                                    closable={false}
                                                    onCancel={this.handleCancel}
                                                >
                                                    <Form
                                                        name="basic"
                                                        onFinish={this.formSetLocation}
                                                        onFinishFailed={this.formSetLocationFailed}
                                                        size={"large"}
                                                        className="customise-form"
                                                        ref={this.addressFormRef}
                                                        initialValues={{
                                                                appartment:this.state.appartment,
                                                                street:this.state.street,
                                                                city:this.state.city
                                                        }}
                                                    >
                                                        {/* <div style={{ 'display': "inline-block", "width": "100%" }}>
                                                            <Map
                                                                google={this.props.google}
                                                                center={{ lat: 18.5204, lng: 73.8567 }}
                                                                height='300px'
                                                                zoom={15}
                                                                autoComplete={true}
                                                                setAddress={this.setAddress}
                                                            />
                                                        </div> */}

                                                        {/* <br></br> */}
                                                        <Form.Item
                                                            name="appartment"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please input appartment/villa no!',
                                                                },
                                                            ]}
                                                        >
                                                            <Input placeholder="* Appartment/Villa No " className="mt-4" />
                                                        </Form.Item>
                                                        <Form.Item
                                                            name="street"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please input street no!',
                                                                },
                                                            ]}
                                                        >
                                                            <Input placeholder="* Street No" />
                                                        </Form.Item>
                                                        <Form.Item
                                                            name="city"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please input city!',
                                                                },
                                                            ]}
                                                        >
                                                            <Input placeholder="* City" />
                                                        </Form.Item>

                                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                            <Form.Item className="mb-0 text-right">
                                                                <Button className="text-muted" type="link" onClick={this.handleCancel}>
                                                                    Cancel
                                                                </Button>
                                                                <Button type="primary" htmlType="submit" className="btn-blue btn-150 rounded-100">
                                                                    Confirm & Proceed
                                                                </Button>
                                                            </Form.Item>
                                                        </Col>
                                                    </Form>
                                                </Modal>
                                            </Col>
                                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                <Form.Item
                                                    name="phone"
                                                    size={"large"}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input your phone number!',
                                                        },
                                                    ]}
                                                >
                                                    <PhoneInput
                                                        inputStyle={{ width: '100%', height: '53px' }}
                                                        country={'ae'}
                                                        placeholder={"Add Phone number"}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                <Form.Item
                                                    name="emiratesId"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input your emirates ID!',
                                                        },
                                                    ]}
                                                >
                                                    <MaskedInput
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        placeholder={"Add Emirates ID"}
                                                        mask="111-1111-1111111-1"
                                                        onChange={this.idChange}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            {
                                                !this.props.paymentFailed
                                                &&
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                <Form.Item
                                                    name="nationality"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please select your nationality!',
                                                        },
                                                    ]}
                                                    value={this.state.nationality}
                                                    initialValue={this.state.nationality}
                                                >
                                                    <ReactFlagsSelect
                                                        className="flag-select"
                                                        selected={this.state.nationality}
                                                        onSelect={(code) => this.setNatinality(code)}
                                                        placeholder={"Select Nationality"} searchable={true}
                                                    ></ReactFlagsSelect>
                                                </Form.Item>
                                            </Col>
                                            }
                                            {
                                                !this.props.paymentFailed
                                                &&
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} className="upload-id-card">
                                                <Row gutter={15}>
                                                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                                        <Form.Item
                                                            name="frontID"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please upload emirates Id (front)',
                                                                },
                                                            ]}
                                                            onClick={this.idUploadClick}
                                                        >
                                                            <Upload
                                                                maxCount={1}
                                                                accept={ALLOWED_EXTENSION}
                                                                customRequest={(op) => this.uploadId(op, 'front')}
                                                                onChange={this.idFrontChange}
                                                                disabled={this.state.idUploadFlag}
                                                                // showUploadList={true}
                                                                wrapperCol={{ sm: 24 }}
                                                                style={{
                                                                    width: '100% !important'
                                                                }}>
                                                                <Button icon={<UploadOutlined />}>Front Side of Emirates ID</Button>
                                                            </Upload>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                                        <Form.Item
                                                            name="backID"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please upload emirates Id (back)',
                                                                },
                                                            ]}
                                                            onClick={this.idUploadClick}
                                                        >
                                                            <Upload
                                                                maxCount={1}
                                                                accept={ALLOWED_EXTENSION}
                                                                customRequest={(op) => this.uploadId(op, 'back')}
                                                                onChange={this.idBackChange}
                                                                disabled={this.state.idUploadFlag}
                                                                // showUploadList={true}
                                                                wrapperCol={{ sm: 24 }}
                                                                style={{
                                                                    width: '100% !important'
                                                                }}>
                                                                <Button icon={<UploadOutlined />}>Back Side of Emirates ID</Button>
                                                            </Upload>
                                                        </Form.Item>
                                                        
                                                        
                                                    </Col>
                                                </Row>
                                            </Col>
                                            }
                                            
                                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                <div className="add-pet text-center mt-4 position-static">
                                                    <Button type={"link"} onClick={this.showModalSP} className="text-light-green">
                                                        <div className="icon"><i className="fa fa-plus-circle"></i></div>
                                                        Add secondary pet parent
                                                    </Button>
                                                </div>
                                                <Modal
                                                    className="sec-parent-modal"
                                                    title={<div className="text-dark-green font-600">Secondary Pet Parent Details</div>}
                                                    visible={this.state.visibleSP}
                                                    footer={false}
                                                    closable={false}
                                                    onCancel={this.handleCancelSP}
                                                >
                                                    <Form
                                                        name="basic"
                                                        onFinish={this.formSetSecPetParent}
                                                        onFinishFailed={this.formSetSecPetParentFailed}
                                                        size={"large"}
                                                        className="customise-form"
                                                        ref={this.secPetParentFormRef}
                                                    >
                                                        {/* <div style={{ 'display': "inline-block", "width": "100%" }}>
                                                            <Map
                                                                google={this.props.google}
                                                                center={{ lat: 18.5204, lng: 73.8567 }}
                                                                height='300px'
                                                                zoom={15}
                                                                autoComplete={true}
                                                                setAddress={this.setAddress}
                                                            />
                                                        </div> */}

                                                        {/* <br></br> */}
                                                        <Form.Item
                                                            name="secParentName"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please input name!',
                                                                },
                                                            ]}
                                                        >
                                                            <Input placeholder="* Name " className="mt-4" />
                                                        </Form.Item>
                                                        <Form.Item
                                                            name="secParentAddress"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please input address!',
                                                                },
                                                            ]}
                                                        >
                                                            <Input placeholder="* Address" />
                                                        </Form.Item>
                                                        <Form.Item
                                                            name="city"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please input phone no!',
                                                                },
                                                            ]}
                                                        >
                                                            <Input placeholder="* Phone No" />
                                                        </Form.Item>

                                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                            <Form.Item className="mb-0 text-right">
                                                                <Button className="text-muted" type="link" onClick={this.handleCancelSP}>
                                                                    Cancel
                                                                </Button>
                                                                <Button type="primary" htmlType="submit" className="btn-blue btn-150 rounded-100">
                                                                    Confirm & Proceed
                                                                </Button>
                                                            </Form.Item>
                                                        </Col>
                                                    </Form>
                                                </Modal>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="section-gray">
                                        <Row gutter={15}>
                                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                <Title className="text-light-green" level={2}>Pet Cloud Login</Title>
                                            </Col>
                                            {
                                                this.props.paymentFailed
                                                &&
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                    <Form.Item
                                                        initialValue={this.props.preQoute.petsWithPlans[0]?.email}
                                                        name="email_address"
                                                        siz
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input your email address!',
                                                            },
                                                        ]}
                                                    >
                                                        <Input placeholder={"Email Address*"} />
                                                    </Form.Item>
                                                </Col>
                                            }
                                            {
                                                !this.props.paymentFailed
                                                &&
                                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                    <Form.Item
                                                        initialValue={this.props.preQoute.petsWithPlans[0]?.email}
                                                        name="email_address"
                                                        siz
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input your email address!',
                                                            },
                                                        ]}
                                                    >
                                                        <Input placeholder={"Email Address*"} />
                                                    </Form.Item>
                                                </Col>
                                            }
                                            
                                            {
                                                !this.props.paymentFailed
                                                &&
                                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                <Form.Item
                                                    name="confirm_email_address"
                                                    dependencies={['email_address']}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please confirm your email address!',
                                                        },
                                                        ({ getFieldValue }) => ({
                                                            validator(_, value) {
                                                                if (!value || getFieldValue('email_address') === value) {
                                                                    return Promise.resolve();
                                                                }
                                                                return Promise.reject(new Error('Entered emails does not match!'));
                                                            },
                                                        }),
                                                    ]}
                                                >
                                                    <Input placeholder={"Confirm Email Address*"} />
                                                </Form.Item>
                                            </Col>
                                            }
                                            
                                            {
                                                !this.props.paymentFailed
                                                &&
                                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                    <Form.Item
                                                        name="password"
                                                        size={"large"}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input your password',
                                                            },
                                                            // pattern: '/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/',
                                                            // {
                                                            //     pattern: '/^(?:(?=.*[a-z])(?:(?=.*[A-Z])(?=.*[\d\W])|(?=.*\W)(?=.*\d))|(?=.*\W)(?=.*[A-Z])(?=.*\d)).{8,}$/',
                                                            //     message: 'Password should be 6-16 characters long with atleast one character and number!',
                                                            // },
                                                        ]}
                                                    >
                                                        <PasswordInput style={{ lineHeight: '10px' }} placeholder={"Password*"} />
                                                        {/* <Input type="password" placeholder={"Password*"} /> */}
                                                    </Form.Item>
                                                </Col>
                                            }
                                            {
                                                !this.props.paymentFailed
                                                &&
                                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                    <Form.Item
                                                        name="confirm_password"
                                                        dependencies={['password']}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please confirm your password',
                                                            },
                                                            ({ getFieldValue }) => ({
                                                                validator(_, value) {
                                                                    if (!value || getFieldValue('password') === value) {
                                                                        return Promise.resolve();
                                                                    }
                                                                    return Promise.reject(new Error('Entered password does not match!'));
                                                                },
                                                            }),
                                                        ]}
                                                    >
                                                        <Input type="password" placeholder={"Confirm Password*"} />
                                                    </Form.Item>
                                                </Col>
                                            }
                                            
                                        </Row>
                                    </div>
                                </div>
                                }
                                <div className="section-gray pet-summary">
                                    <Row gutter={15}>
                                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                            <Title className="text-light-green" level={2}>Payment Summary</Title>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={12} xl={12} justify="end">
                                            {/* <Form.Item
                                                className="text-center"
                                                name="type"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please select type! *',
                                                    }
                                                ]}
                                            > 
                                                
                                                <Radio.Group buttonStyle="solid" className={`btn-sliding btn-payment-slider`} onChange={(e) => this.onPaymentMethodChange(e)} defaultValue={'ot'}>
                                                         <Radio.Button value="ot">One Time Payment</Radio.Button>
                                                         <Radio.Button value="mi">Monthly Installments</Radio.Button>
                                                </Radio.Group>
                                             </Form.Item> */}
                                        </Col>
                                        {
                                            this.state.petsWithPlans.map((pp, objIndex) => (
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                    <Row gutter={15} align="middle">
                                                        <Col xs={24} sm={16} md={16} lg={16} xl={16}>
                                                            <div className="pet-name">
                                                                <Image
                                                                    preview={false}
                                                                    className="img-fluid"
                                                                    src={this.checkType(pp.type)}
                                                                />
                                                                <span className="name-text">{upperCase(pp.name)}</span>
                                                            </div>
                                                        </Col>
                                                        <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                                                            <div class="plan-price">
                                                                {/* <Title level={4} className="text-dark-green font-600">{CURRENCY} {Math.ceil((pp.plans[pp.planId]) * 3.67)}</Title> */}
                                                                {pp.plans && <Fragment> <sub>{CURRENCY}</sub> <span className="price-amount"> {pp.planVal}</span></Fragment>}
                                                                <div class="payment-period"></div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Divider />
                                                    <div className="breakdown-details mb-4">
                                                        <Row gutter={15} align="middle">
                                                            {
                                                                // this.props.preQoute?.petsWithPlans.map((pp, objIndex) => (
                                                                <Fragment>
                                                                    <Col xs={24} sm={11} md={11} lg={11} xl={11}>
                                                                        <ul className="detail-inner left">
                                                                            <li>{this.props.plans[pp.planId].title} Plan <span>{CURRENCY} {(BenifitsMap[this.props.plans[pp.planId].annualy])}</span></li>
                                                                            <li>Deductible <span>{CURRENCY} {Deduction[pp.deductionId]}</span></li>
                                                                            <li>Reimbursement <span>{Reimburse[pp.reimburseId].reimbursment}%</span></li>
                                                                        </ul>
                                                                    </Col>
                                                                    <Col xs={24} sm={2} md={2} lg={2} xl={2}></Col>
                                                                    <Col xs={24} sm={11} md={11} lg={11} xl={11}>
                                                                        <ul className="detail-inner right">
                                                                            <li>Annual Premium <span>{CURRENCY} {pp.planVal}</span></li>
                                                                            {
                                                                                pp.discount > 0 &&
                                                                                <li>Discount {objIndex ? '(5%)' : ''} <span>{CURRENCY} {pp.discount > 0 ? "-": ""}{pp.discount}</span></li>
                                                                            }
                                                                            <li>Policy Fee <Tooltip title="It is charged only once"><i className="fa fa-question-circle"></i></Tooltip> <span> {CURRENCY} {pp.policyValue}</span></li>
                                                                            <li>VAT (5%) <span>{CURRENCY} {pp.vat}</span></li>
                                                                            <li className="text-red">{upperCase(pp.name)}’s Total <span>{CURRENCY} {pp.total}</span></li>
                                                                        </ul>
                                                                    </Col>
                                                                </Fragment>
                                                                // ))
                                                            }
                                                        </Row>
                                                    </div>
                                                    {/* <Title level={4} className="text-green text-center font-600">Annual Premium &nbsp;&nbsp;&nbsp;&nbsp; <span className="text-red font-400">{CURRENCY} {convertAED(pp.plans[pp.planId])}</span></Title> */}
                                                    {/* <Title level={4} className="text-green text-center font-600 mt-0 mb-5">Your account will be charged on the 6th of each month</Title> */}
                                                </Col>
                                            ))
                                        }

                                        {/* <div className="amount-total w-100 text-center">
                                            <Title level={3} className="text-light-green font-600">Today’s Total</Title>
                                            <Title level={4} className="text-dark-green font-600 mt-0">Total - {CURRENCY} {this.state.total}</Title>
                                            <Title level={4} className="text-dark-green font-600 mt-0">VAT(5%) - {CURRENCY} {applyDiscountVat(this.state.total, 5, 'discount')}</Title>
                                            <Title level={4} className="text-dark-green font-600 mt-0">Policy Fee (for 1st year only) - {CURRENCY} 50</Title>
                                            <Title level={4} className="text-dark-green font-600 mt-0">Total Payable - {CURRENCY} {(this.state.total + 50 + applyDiscountVat(this.state.total, 5, 'discount'))}</Title>
                                            <Paragraph className="text-muted">By Clicking purchase you agree <br /> to our <Link className="text-light-green" to={"/"}>terms & conditions</Link></Paragraph>
                                        </div> */}
                                    </Row>
                                </div>
                                {/* <div className="total-amount mb-3">
                                    Today’s Total
                                    <div class="plan-price">
                                        {<Fragment> <sub>{CURRENCY}</sub> <span className="price-amount">{parseFloat(this.state.total).toFixed(2)}</span></Fragment>}
                                    </div>
                                </div> */}
                                {/* <Form.Item
                                    name="confirm"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please check the option',
                                        },
                                    ]}
                                >
                                    <Radio className="custom-radio single-radio" value={'confirm'}>Just tick here to confirm you have read to our terms and conditions</Radio>
                                </Form.Item> */}
                                <Form.Item className="mt-0">
                                    
                                    <div>
                                        
                                            {
                                                (this.state.enableTamara && this.state.tamaraTotal < 20000) &&
                                                <Row gutter={10}>
                                                <Col xl={12} lg={12} md={12} sm={12} xs={24} offset={0}>
                                                    <div className={"section-gray pet-summary price-selector p-4 "+(this.state.selectedPaymentMethod == 'mi' ? 'active' : '')} onClick={() => {this.onPaymentMethodChange('mi')}}>
                                                        <Title level={3} className="text-light-green">Pay with <img style={{width:100}} src={tamaralogo} /></Title>
                                                        <Paragraph className="notes">
                                                            You can pay by four monthly installments
                                                            {/* <Radio.Group buttonStyle="solid" className={`btn-sliding btn-payment-slider`} onChange={(e) => this.onInstallmentCountChange(e)} defaultValue={4}>
                                                                <Radio.Button value={2}>2</Radio.Button>
                                                                <Radio.Button value={3}>3</Radio.Button>
                                                                <Radio.Button value={4}>4</Radio.Button>
                                                            </Radio.Group>  */}
                                                        </Paragraph>

                                                        <Title level={4} className="text-light-green">Today's Payment</Title>

                                                        <div class="plan-price text-center">
                                                            {<Fragment> <sub>{CURRENCY}</sub> <span className="price-amount">{parseFloat(this.state.tamaraInstallmentValue).toFixed(2)}</span></Fragment>}
                                                        </div>

                                                        {/* <div className="steps-action full-width text-center mt-3">
                                                            <Button size="large" type="primary" className="btn-red rounded-100" htmlType="submit" onClick={async() => await this.submitPayment('mi')}>Purchase</Button>
                                                        </div> */}
                                                    </div>
                                                </Col>
                                                <Col xl={12} lg={12} md={12} sm={12} xs={24} offset={0}>
                                                    <div className={"section-gray pet-summary price-selector p-4 "+(this.state.selectedPaymentMethod == 'ot' ? 'active' : '')} onClick={() => {this.onPaymentMethodChange('ot')}}>
                                                        <Title level={3} className="text-light-green">Annual Payment</Title>
                                                        <Paragraph className="notes" >
                                                            Make one time payment and save <strong >{CURRENCY} {parseFloat(this.state.tamaraTotal-this.state.total).toFixed(2)}</strong>.
                                                        </Paragraph>

                                                        <Title level={4} className="text-light-green">Today's Payment</Title>
                                                        <div class="plan-price text-center">
                                                            {<Fragment> <sub>{CURRENCY}</sub> <span className="price-amount">{parseFloat(this.state.total).toFixed(2)}</span></Fragment>}
                                                        </div>

                                                        {/* <div className="steps-action full-width text-center mt-3">
                                                            <Button size="large" type="primary" className="btn-red rounded-100" htmlType="submit" onClick={async() => await this.submitPayment('ot')}>Purchase</Button>
                                                        </div> */}
                                                    </div>
                                                </Col>
                                            </Row>
                                            }
                                            
                                        <div className="total-amount">
                                            Now You Pay
                                            <div class="plan-price">
                                                {<Fragment> <sub>{CURRENCY}</sub> <span className="price-amount">{parseFloat((this.state.selectedPaymentMethod == 'ot' ? this.state.total : this.state.tamaraInstallmentValue)).toFixed(2)}</span></Fragment>}
                                            </div>
                                        </div>
                                        <div className="steps-action full-width mt-5 text-center">
                                            <Button size="large" type="primary" className="btn-red rounded-100" htmlType="submit">Purchase</Button>
                                        </div>
                                        <div className="steps-action full-width mt-2 text-center">
                                            <Button type="text" size="large" className="mx-2 rounded-100" onClick={async() => await this.saveLeadInformation()}>
                                                Not ready? Save your quote
                                            </Button>
                                        </div>
                                        </div>
                                </Form.Item>
                            </Form>
                        </Spin>
                    </Col>
                    <Col xl={8} lg={8} md={24} sm={24} xs={24}>
                        <div className="important-date mb-5">
                            <div className="section-heading bg-green mb-3">Important dates</div>
                            <Card>
                                <ul className="w-100">
                                    <li>
                                        <Title level={3} className="text-light-green">Policy effective date</Title>
                                        <time>{longMonth} {date.getDate()}, {date.getFullYear()}</time>
                                    </li>
                                    <li>
                                        <Title level={3} className="text-light-green">Accident coverage starts</Title>
                                        <time>{accMonth} {acc.getDate()}, {acc.getFullYear()}</time>
                                    </li>
                                    <li>
                                        <Title level={3} className="text-light-green">Illness coverage starts</Title>
                                        <time>{illMonth} {ill.getDate()}, {ill.getFullYear()}</time>
                                    </li>
                                    <li>
                                        <Title level={3} className="text-light-green">Knee coverage starts</Title>
                                        <time>{kneMonth} {kne.getDate()}, {kne.getFullYear()}</time>
                                    </li>
                                </ul>
                                <Paragraph className="notes">
                                    <Image
                                        preview={false}
                                        className="img-fluid"
                                        src="/images/icon-warning-red.png"
                                    />
                                    Pre-existing conditions are not covered. For the health of your pet, we recommend an annual exam or to have an exam performed shortly after purchasing the insurance policy.
                                </Paragraph>
                            </Card>
                        </div>
                        <Sidebar />
                    </Col>
                </Row>
            </Fragment>
        );
    }
}

export default yourinfo;
